<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

    <b-card title="Add new monitoredItem">
      <b-form @submit.prevent>
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Config Name"
              label-for="v-itemName"
            >

              <b-form-input
                id="v-sku"
                v-model="monitoredItem.itemName"
                placeholder="Item Name"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SKU"
              label-for="v-sku"
            >
              <b-form-input
                id="v-sku"
                v-model="monitoredItem.sku"
                placeholder="SKU"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Parent SKU"
              label-for="v-parentSku"
            >
              <b-form-input
                id="v-sku"
                v-model="monitoredItem.parentSku"
                placeholder="Parent SKU"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="ASIN"
              label-for="v-asin"
            >
              <b-form-input
                id="v-asin"
                v-model="monitoredItem.asin"
                placeholder="SKU"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Parent ASIN"
              label-for="v-parentAsin"
            >
              <b-form-input
                id="v-parentAsin"
                v-model="monitoredItem.parentAsin"
                placeholder="Parent ASIN"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Style Name"
              label-for="v-styleName"
            >
              <b-form-input
                id="v-styleName"
                v-model="monitoredItem.styleName"
                placeholder="Style Name"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="brand"
              label-for="v-brand"
            >
              <b-form-input
                id="v-brand"
                v-model="monitoredItem.brand"
                placeholder="Brand"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="v-description"
            >
              <b-form-input
                id="v-description"
                v-model="monitoredItem.description"
                placeholder="Description"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint1"
              label-for="v-bulletPoint1"
            >
              <b-form-input
                id="v-bulletPoint1"
                v-model="monitoredItem.bulletPoint1"
                placeholder="bulletPoint1"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint2"
              label-for="v-bulletPoint2"
            >
              <b-form-input
                id="v-bulletPoint2"
                v-model="monitoredItem.bulletPoint2"
                placeholder="bulletPoint2"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint3"
              label-for="v-bulletPoint3"
            >
              <b-form-input
                id="v-bulletPoint3"
                v-model="monitoredItem.bulletPoint3"
                placeholder="bulletPoint3"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint4"
              label-for="v-bulletPoint4"
            >
              <b-form-input
                id="v-bulletPoint4"
                v-model="monitoredItem.bulletPoint4"
                placeholder="bulletPoint4"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="bulletPoint5"
              label-for="v-bulletPoint5"
            >
              <b-form-input
                id="v-bulletPoint5"
                v-model="monitoredItem.bulletPoint5"
                placeholder="bulletPoint5"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="maxOrderQuantity"
              label-for="v-maxOrderQuantity"
            >
              <b-form-input
                id="v-maxOrderQuantity"
                v-model="monitoredItem.maxOrderQuantity"
                placeholder="maxOrderQuantity"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="variationTheme"
              label-for="v-variationTheme"
            >
              <b-form-input
                id="v-variationTheme"
                v-model="monitoredItem.variationTheme"
                placeholder="variationTheme"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="itemType"
              label-for="v-itemType"
            >
              <b-form-input
                id="v-itemType"
                v-model="monitoredItem.itemType"
                placeholder="itemType"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="supplierDeclaredDGHZRegulation"
              label-for="v-supplierDeclaredDGHZRegulation"
            >
              <b-form-input
                id="v-supplierDeclaredDGHZRegulation"
                v-model="monitoredItem.supplierDeclaredDGHZRegulation"
                placeholder="supplierDeclaredDGHZRegulation"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="manufacturer"
              label-for="v-manufacturer"
            >
              <b-form-input
                id="v-manufacturer"
                v-model="monitoredItem.manufacturer"
                placeholder="manufacturer"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="unitCount"
              label-for="v-unitCount"
            >
              <b-form-input
                id="v-unitCount"
                v-model="monitoredItem.unitCount"
                placeholder="unitCount"
                type="number"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="pPUCountType"
              label-for="v-pPUCountType"
            >
              <b-form-input
                id="v-pPUCountType"
                v-model="monitoredItem.pPUCountType"
                placeholder="pPUCountType"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="isHeatSensitive"
              label-for="v-isHeatSensitive"
            >
              <b-form-input
                id="v-isHeatSensitive"
                v-model="monitoredItem.isHeatSensitive"
                placeholder="isHeatSensitive"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="areBatteriesRequired"
              label-for="v-areBatteriesRequired"
            >
              <b-form-input
                id="v-areBatteriesRequired"
                v-model="monitoredItem.areBatteriesRequired"
                placeholder="areBatteriesRequired"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="conditionType"
              label-for="v-conditionType"
            >
              <b-form-input
                id="v-conditionType"
                v-model="monitoredItem.conditionType"
                placeholder="conditionType"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="isExpirationDatedProduct"
              label-for="v-isExpirationDatedProduct"
            >
              <b-form-input
                id="v-isExpirationDatedProduct"
                v-model="monitoredItem.isExpirationDatedProduct"
                placeholder="isExpirationDatedProduct"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="mfrPartNumber"
              label-for="v-mfrPartNumber"
            >
              <b-form-input
                id="v-mfrPartNumber"
                v-model="monitoredItem.mfrPartNumber"
                placeholder="mfrPartNumber"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="SearchTerms"
              label-for="v-SearchTerms"
            >
              <b-form-input
                id="v-SearchTerms"
                v-model="monitoredItem.searchTerms"
                placeholder="SearchTerms"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="productTypeNodeParent"
              label-for="v-productTypeNodeParent"
            >
              <b-form-input
                id="v-productTypeNodeParent"
                v-model="monitoredItem.productTypeNodeParent"
                placeholder="Ex: Health"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="productType"
              label-for="v-productType"
            >
              <b-form-input
                id="v-productType"
                v-model="monitoredItem.productType"
                placeholder="Ex: Massager"
                required
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveItem"
            >
              Add
            </b-button>

            <router-link :to="{ name : 'amz-selling-partners-monitored', params: {accountId:accountId} }">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Back
              </b-button>
            </router-link>

          </b-col>
        </b-row>
      </b-form>

    </b-card>

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      monitoredItem: { },
      fieldsToIgnore: {
        itemName: true,
      },
      channelSelected: null,
      accountId: null,
      channelOptions: [
        { value: 'Amazon', text: 'Amazon' },
        { value: 'Website', text: 'Website' },

      ],
    }
  },
  mounted() {
    this.accountId = this.$route.params.accountId
  },
  methods: {
    saveItem() {
      this.loading = true
      this.monitoredItem.accountId = this.accountId
      this.$store.dispatch('amzSellingPartnerAccount/createMonitoredItem', this.monitoredItem)
        .then(result => {
          this.$router.push({ name: 'amz-selling-partners-monitored', params: { accountId: this.accountId } })
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while saving', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
